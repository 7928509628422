import { Model } from '@/models/Model';
import { Component, Vue, Prop } from 'vue-property-decorator';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import MiFileUpload from '@/components/mi-file-upload/MiFileUpload';

@Component<MediaDialog>({})
export default class MediaDialog extends Vue {
  // #region @Props
  /**
   * ie.
   * @Prop()
   * protected user!: User
   */
  @Prop({ default: 'Media toevoegen' })
  protected title!: string;

  @Prop()
  protected model!: Model;
  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */

  protected isLoading = false;

  protected uploadedFile = '';

  protected selectedFiles: File[] = [];
  // #endregion

  // #region Lifecycle Hooks / Init

  // #endregion

  // #region Class methods

  protected mounted() {
    //
  }

  protected handleItemDropped(file: File) {
    if (! file) {
      return;
    }

    this.selectedFiles.push(file);
    const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
    fileUploadComponent.uploaded();
  }

  protected close() {
    this.$emit('input', false);
    this.$emit('close');
  }
  // #endregion

  // #region Async methods
  /**
   * ie.
   * protected async fetchUserCompany(): Promise<void> {
   *  this.company = await new Company().filter({user: this.user.id}).all();
   * }
   */

  protected async create() {
    if (! this.model) {
      return;
    }

    this.isLoading = true;

    const filePromises = this.selectedFiles.map((file) => this.addFileToArticle(file));
    const result = await Promise.all(filePromises);

    if (result) {
      this.$emit('created');
      this.close();
      this.isLoading = false;
    }
  }

  protected async addFileToArticle(file: File) {
    return await await this.model
      .create({
        file,
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });
  }
  // #endregion

  // #region Getters & Setters

  // #endregion

  // #region @Watchers

  // #endregion
}
