var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultDialog",
    {
      on: {
        close: function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _vm.isLoading
        ? [
            _c(
              "template",
              { slot: "content" },
              [_c("LoaderCard", { attrs: { type: "spinner--center" } })],
              1
            )
          ]
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "template",
              { slot: "content" },
              [
                _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _c(
                      "v-layout",
                      {
                        staticClass: "fileUpload__container",
                        attrs: {
                          "fill-height": "",
                          "mb-3": "",
                          "pa-1": "",
                          wrap: ""
                        }
                      },
                      [
                        _c("MiFileUpload", {
                          ref: "fileUpload",
                          attrs: {
                            clearAfterUpload: false,
                            manualSubmit: true,
                            multiple: true
                          },
                          on: {
                            itemDropped: function($event) {
                              return _vm.handleItemDropped($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "template",
              { slot: "actions" },
              [
                _c(
                  "v-layout",
                  { attrs: { "justify-end": "", "align-center": "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { shrink: "" } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "fadedText link mr-3",
                            on: {
                              click: function($event) {
                                return _vm.close()
                              }
                            }
                          },
                          [_vm._v("Annuleren")]
                        ),
                        _c(
                          "MiButton",
                          {
                            attrs: { color: "primary", small: "true" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.create($event)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("actions.add")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }