var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-treeview", {
    staticClass: "library-tree",
    attrs: {
      "active-class": "library-tree--active",
      items: _vm.items,
      search: _vm.search,
      "item-key": "id",
      "item-text": "title",
      "return-object": "",
      transition: "",
      open: _vm.open
    },
    scopedSlots: _vm._u(
      [
        {
          key: "prepend",
          fn: function(ref) {
            var item = ref.item
            var open = ref.open
            return [
              item.internal_type === "folders"
                ? _c("v-icon", { staticClass: "library-icon" }, [
                    _vm._v(" " + _vm._s(open ? "folder_open" : "folder") + " ")
                  ])
                : item.icon
                ? _c(
                    "v-icon",
                    {
                      staticClass: "library-icon",
                      on: {
                        click: function($event) {
                          return _vm.onClickSelected(item)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(item.icon) + " ")]
                  )
                : _c("v-icon", [_vm._v(" article ")])
            ]
          }
        },
        {
          key: "label",
          fn: function(ref) {
            var item = ref.item
            return [
              _c(
                "span",
                {
                  staticClass: "library-name",
                  class: _vm.getActiveClass(item),
                  on: {
                    click: function($event) {
                      return _vm.onClickSelected(item)
                    }
                  }
                },
                [
                  _vm._t("label", [_vm._v(" " + _vm._s(item.title) + " ")], {
                    item: item
                  })
                ],
                2
              )
            ]
          }
        },
        _vm.editable
          ? {
              key: "append",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-menu",
                    {
                      staticClass: "submenuList",
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c("v-icon", _vm._g({}, on), [
                                  _vm._v("more_vert")
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c(
                        "v-list",
                        [
                          item.internal_type === "folders"
                            ? [
                                _c(
                                  "v-list-tile",
                                  {
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.onActionClick("folder", item)
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", { staticClass: "mr-2" }, [
                                      _vm._v("create_new_folder")
                                    ]),
                                    _c("v-list-tile-title", [
                                      _vm._v(" Nieuwe folder ")
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-tile",
                                  {
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.onActionClick(
                                          "article",
                                          item
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", { staticClass: "mr-2" }, [
                                      _vm._v("note_add")
                                    ]),
                                    _c("v-list-tile-title", [
                                      _vm._v(" Nieuw artikel ")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            : _vm._e(),
                          _c(
                            "v-list-tile",
                            {
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.onActionClick("edit", item)
                                }
                              }
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v("edit")
                              ]),
                              _c("v-list-tile-title", [_vm._v(" Wijzigen ")])
                            ],
                            1
                          ),
                          _c(
                            "v-list-tile",
                            {
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.onActionClick("delete", item)
                                }
                              }
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v("delete")
                              ]),
                              _c("v-list-tile-title", [_vm._v(" Verwijderen ")])
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ]
              }
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }