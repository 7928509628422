import { Article } from '@/models/Article';
import { Folder } from '@/models/Folder';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

@Component<LibraryTree>({})
export default class LibraryTree extends Vue {
  // #region @Props
  /**
   * ie.
   * @Prop()
   * protected user!: User
   */
  @Prop()
  protected items!: LibraryItem[];

  @Prop()
  protected active!: LibraryItem;

  @Prop({ default: true })
  protected editable!: boolean;

  @Prop({ default: '' })
  protected search!: string;
  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */

   protected selected: LibraryItem | null = null;

   protected open: LibraryItem[] = [];
   // #endregion

   // #region Lifecycle Hooks / Init

   protected mounted(): void {
     this.initializeActive();
   }
   // #endregion

   // #region Class methods

   protected onActionClick(type: string, item?: LibraryItem): void {
     if (type === 'folder') {
       this.$emit('onClickFolder', item);
     }
     if (type === 'article') {
       this.$emit('onClickArticle', item);
     }
     if (type === 'edit') {
       this.$emit('onClickEdit', item);
     }
     if (type === 'delete') {
       this.$emit('onClickDelete', item);
     }
   }

   protected onClickSelected(article: LibraryItem): void {
     if (article.internal_type === 'folders') {
       return;
     }

     this.selected = article;
     this.$emit('onClickSelected', article);
   }

   protected getActiveClass(article: LibraryItem) {
     if (article.internal_type === 'folders') {
       return;
     }

     return { 'library-name--active': this.selected && article.id === this.selected.id };
   }
   // #endregion

   // #region Async methods
   /**
   * ie.
   * protected async fetchUserCompany(): Promise<void> {
   *  this.company = await new Company().filter({user: this.user.id}).all();
   * }
   */
   // #endregion

   // #region Getters & Setters

   // #endregion

   // #region @Watchers

  @Watch('active')
   protected initializeActive() {
     this.selected = this.active ? this.active : null;
   }
  // #endregion
}

// export interface LibraryItem extends Folder, Article {
//   id?: string;
//   title?: string;
//   content?: string;
//   description?: string;
//   notes?: string;
//   icon?: string;
//   internal_type?: string;
//   children?: LibraryItem[];

// }

export type LibraryItem = Folder | Article;
