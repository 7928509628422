import { Article } from '@/models/Article';
import { Folder } from '@/models/Folder';
import { Component, Vue, Prop } from 'vue-property-decorator';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { LibraryItem } from '@/components/LibraryTree/LibraryTree';
import { User } from '@/models/User';

@Component<LibraryDialog>({})
export default class LibraryDialog extends Vue {
  // #region @Props
  /**
   * ie.
   * @Prop()
   * protected user!: User
   */
  @Prop()
  protected selected!: LibraryItem;

  @Prop()
  protected dialogType!: DialogType;

  @Prop({ default: 'library' })
  protected libraryType!: LibraryType;
  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */

  protected isLoading = false;

  protected libraryItemPayload = {
    title: '',
  };
  // #endregion

  // #region Lifecycle Hooks / Init

  // #endregion

  // #region Class methods

  protected mounted() {
    this.libraryItemPayload.title = this.dialogType === 'edit' ? this.selected?.title || '' : '';
  }

  protected createOrUpdateLibraryItem() {
    if (this.selected && this.dialogType === 'edit') {
      this.updateLibraryItem();
    } else {
      this.createLibraryItem();
    }
  }

  protected close() {
    this.$emit('input', false);
    this.$emit('close');
  }
  // #endregion

  // #region Async methods
  /**
   * ie.
   * protected async fetchUserCompany(): Promise<void> {
   *  this.company = await new Company().filter({user: this.user.id}).all();
   * }
   */

  protected async createLibraryItem() {
    this.isLoading = true;

    let result;

    if (this.dialogType === 'folder') {
      result = await new Folder().create({
        title: this.libraryItemPayload.title,
        type: this.libraryType,
        parent: this.selected?.id || null,
      })
        .catch((error: AxiosError) => {
          ErrorHandler.network(error);
        });
    }

    if (this.dialogType === 'article') {
      const user = await new User().current().then((user: User) => user);
      result = await new Article().create({
        title: this.libraryItemPayload.title,
        folder: this.selected?.id || null,
        status: 'draft',
        user: user.uuid,
      })
        .catch((error: AxiosError) => {
          ErrorHandler.network(error);
        });
    }

    this.$emit('created', result);
    this.close();
    this.isLoading = false;
  }

  protected async updateLibraryItem() {
    if (! this.selected) {
      return;
    }

    let result;

    this.isLoading = true;
    if (this.selected.internal_type === 'folders') {
      result = await new Folder({ id: this.selected.id })
        .update({
          title: this.libraryItemPayload.title,
          type: this.libraryType,
        })
        .catch((error: AxiosError) => {
          ErrorHandler.network(error);
        });
    }

    if (this.selected.internal_type === 'articles') {
      result = await new Article({ id: this.selected.id })
        .update({
          title: this.libraryItemPayload.title,
          type: this.libraryType,
        })
        .catch((error: AxiosError) => {
          ErrorHandler.network(error);
        });
    }

    this.$emit('created', result);
    this.close();
    this.isLoading = false;
  }
  // #endregion

  // #region Getters & Setters

  protected get libraryItemTitle(): string {
    if (this.dialogType === 'folder') {
      return 'Folder aanmaken';
    }

    if (this.dialogType === 'article') {
      return 'Artikel aanmaken';
    }

    if (this.selected && (this.dialogType === 'edit')) {
      return `${this.selected.title} aanpassen`;
    }

    return '';
  }
  // #endregion

  // #region @Watchers

  // #endregion
}

type DialogType = 'article' | 'edit' | 'folder';

type LibraryType = 'library' | 'changelist';
