var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "draggable",
        _vm._b(
          {
            staticClass: "item-container",
            attrs: {
              tag: "div",
              list: _vm.list,
              value: _vm.value,
              parent: _vm.parent
            },
            on: {
              "update:list": function($event) {
                _vm.list = $event
              },
              change: _vm.onChange
            }
          },
          "draggable",
          _vm.dragOptions,
          false
        ),
        _vm._l(_vm.internalValue, function(element) {
          return _c(
            "div",
            { key: element.id, staticClass: "item-group" },
            [
              _c(
                "div",
                { staticClass: "item" },
                [
                  element.internal_type === "folders"
                    ? [
                        _c("v-icon", { staticClass: "mr-1" }, [
                          _vm._v("folder_open")
                        ])
                      ]
                    : _c("v-icon", { staticClass: "mr-1" }, [
                        _vm._v(_vm._s(element.icon))
                      ]),
                  _c("span", [_vm._v(_vm._s(element.title))]),
                  _c("v-icon", { staticClass: "ml-1" }, [
                    _vm._v("drag_indicator")
                  ])
                ],
                2
              ),
              _c("sortable-folder-tree", {
                staticClass: "item-sub",
                attrs: { list: element.children, parent: element },
                on: { itemChanged: _vm.itemChanged }
              })
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }