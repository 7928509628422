import { LibraryItem } from '@/components/LibraryTree/LibraryTree';
import { Component, Vue, Prop } from 'vue-property-decorator';
import draggable from 'vuedraggable';

@Component<SortableFolderTree>({
  name: 'sortable-folder-tree',
  components: {
    draggable,
  },
})
export default class SortableFolderTree extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */
 @Prop()
 protected value!: any;

 @Prop()
 protected parent!: LibraryItem;

 @Prop()
 protected list!: LibraryItem[];
 // #endregion

 // #region @Refs
 /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
 // #endregion

 // #region Class properties
 /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */

  protected dragOptions = {
    animation: 0,
    group: 'description',
    disabled: false,
    ghostClass: 'ghost',
  };

  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */

  // #endregion

  // #region Class methods
  protected onChange(value: SortValue) {
    if (value.added || value.moved) {
      this.$emit('itemChanged', {
        parent: this.parent,
        item: value,
        list: this.internalValue,
      });
    }
  }

  protected itemChanged(item: any) {
    this.$emit('itemChanged', item);
  }
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */
  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get internalValue() {
    return this.value ? this.value : this.list;
  }
  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
}

interface SortValue {
  removed?: SortValueItem
  added?: SortValueItem;
  moved?: SortValueItem;
}

interface SortValueItem {
  element?: any;
  newIndex?: number;
  oldIndex?: number;
}

export interface SortItem {
  parent: LibraryItem;
  item: SortValue;
  list: LibraryItem[];
}
